import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SectionTitle from '../../components/sectiontitle.jsx';
import Section from '../../components/section.jsx';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import C3D1 from '../../images/portfolio/cognitive3d/cognitive3d-1.png';
import C3D2 from '../../images/portfolio/cognitive3d/cognitive3d-2.png';
import C3D3 from '../../images/portfolio/cognitive3d/cognitive3d-3.png';
import C3D4 from '../../images/portfolio/cognitive3d/cognitive3d-4.png';


function PortfolioCognitive3D() {
  return (
    <Layout currentPage={[{title:"Portfolio", url:"portfolio"}, {title:"Cognitive3D", url:"/portfolio/cognitive3d"}]}>
      <SEO
        keywords={[`Robert Merki`, `Robmerki`, `Rob Merki`, `@robmerki`,`Merki.ca`]}
        title="Cognitive3D"
      />

      
      <Section>
        <SectionTitle title="Cognitive3D" />
        <p>
          I was Director of Product at Cognitive3D for 4.5 years.
        </p>

        <p>
          Cognitive3D is an analytics platform for virtual reality, specifically targetted towards enterprise customers who need to measure training/simulation scenarios.
        </p>

        <p>
          Virtual reality includes an extra dimension compared to traditional web analytics, so we had to invent new ways to measure human behaviour in 3d space.
        </p>
        
        <p>
          You can learn more about Cognitive3D here: <a href="https://cognitive3d.com">cognitive3d.com</a>
        </p>

        <SectionTitle title="My Work" />
        <p>
        My official role was to create &amp; manage product strategy, but since I was the first employee at a growing startup, I did many different things along the way.
        </p>
        
        <p>
        Here is a list of the majority of my other responsibilities:
          <ul className="list-disc list-inside">
            <li>Close work with Director of Engineering &amp; CEO to translate product requirements into launched features</li>
            <li>Managing engineering roadmap</li>
            <li>Hiring &amp; recruiting of engineering team</li>
            <li>All UX &amp; design work</li>
            <li>Front-end design &amp; development</li>
            <li>Creating &amp; maintaining 3D analytics visualization tools</li>
            <li>Creating all video demos &amp; teasers.</li>
            <li>Email template creation</li>
            <li>Creating &amp; testing Unity3D and Unreal Engine SDK integration demos</li>
            <li>Creating &amp; editing developer documentation</li>
            <li>Managing JIRA ticket flow</li>
            <li>Tradeshow demos &amp; customer engagement</li>
            <li>Customer success with enterprise clients</li>
          </ul>
        </p>

        <SectionTitle title="Tech" />
        <p>
          I worked with a large number of different technologies, here are most of them:
          <ul className="list-disc list-inside">
            <li>Html / CSS</li>
            <li>ReactJS</li>
            <li>Unity3D / C#</li>
            <li>Unreal Engine / C++</li>
            <li>Blender</li>
            <li>Python</li>
            <li>Java</li>
            <li>Git</li>
            <li>Sketch &amp; Figma</li>
            <li>Adobe Creative Suite</li>
            <li>Da Vinci Resolve</li>
          </ul>
        </p>
        
        <SectionTitle title="Images" />

        <p>
          Due to the sensitive nature of the simulations analyzed on the platform, I am limited with what I can post publicly here. Please let me know if you'd like to learn more about my work.
        </p>

        <Zoom><img className="w-full h-auto" src={C3D1} /></Zoom>
        <Zoom><img className="w-full h-auto" src={C3D2} /></Zoom>
        <Zoom><img className="w-full h-auto" src={C3D3} /></Zoom>
        <Zoom><img className="w-full h-auto" src={C3D4} /></Zoom>

      </Section>
      
    </Layout>
  );
}


export default PortfolioCognitive3D;